import React, { Component } from 'react';
import { Slide, toast } from "react-toastify";

import './styles.scss'
import getParameterByName from 'utils/queryString';
import api from "utils/api";
import logo from "images/logo.png";
import Footer from "../Footer";

class Confirmation extends Component {
  state = {
    title: '',
    text: '',
    isAlreadyConfirmed: false,
    email: '',
    success: false,
    resetPasswordModalOpen: false,
    password: '',
    confirmPassword: '',
    inputType: {
      password: 'password',
      confirmPassword: 'password'
    }
  }

  componentDidMount() {
    const id = getParameterByName('code');
    if (id && window.sessionStorage.getItem('confirmationCode') === id) {
      this.setState({
        success: true,
        title: "Erfolg",
        text: "Die E-Mail wurde erfolgleich aktiviert"
      })
    } else {
      this.activateUserAccount();
    }
  };

  toggleInputTypeSwitch = (e) => {
    const { inputType } = this.state;

    this.setState({
      inputType: {
        ...inputType,
        [e.target.name]: this.state.inputType[e.target.name] === 'password' ? 'text' : 'password'
      }
    })
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  activateUserAccount = async () => {
    const id = getParameterByName('code');
    this.setState({
      loading: true,
    });

    const { response, error } = await api.activateUserAccount(id);

    if (error && error.detail === 'username.or.email.is.busy') {
      return this.props.history.push('/forgot-password')
    }
    if (!response || error) {
      // return this.setState({
      //   title: "Fehler",
      //   text: "Etwas schief gelaufen. Versuchen Sie es bitte erneut.",
      //   loading: false,
      // })
      return this.showMessage('Etwas schief gelaufen. Versuchen Sie es bitte erneut.');
    };
    if (response.status === "already_confirmed") {
      this.setState({
        isAlreadyConfirmed: true,
        title: "Achtung!",
        success: false,
        text: "Diese E-Mail-Adresse wurde bereits bestätigt. Du kannst dich nun mit deiner E-Mail-Adresse und deinem Passwort in der App anmelden."
      })
    } else if (response.status === "account_disabled") {
      this.setState({
        isAlreadyConfirmed: false,
        title: "Hinweis",
        success: false,
        text: "Wir haben dir soeben eine E-Mail gesendet. Bitte bestätige über den Link deine E-Mailadresse und los geht‘s!"
      })
    } else if (response.status === "ok") {
      this.setState({
        success: true,
        isAlreadyConfirmed: false,
        title: "Erfolg",
        text: "Die E-Mail wurde erfolgleich aktiviert. \nUnd Jetzt können Sie die App verwenden:"
      })
    } else if (response.status === "not_found") {
      this.setState({
        title: "Fehler",
        text: "Der von Link eingegebene Bestätigungscode ist ungültig."
      })
    } else {
      this.setState({
        title: "Erfolg",
        text: "Die E-Mail wurde erfolgleich aktiviert"
      })
    }

    this.setState({
      loading: false,
      error: !!error
    });
  }

  toggleResetModal = () => {
    this.props.history.push('/forgot-password')
  };

  resetPassword = async () => {
    const { email } = this.state;

    const { response, error } = await api.resetPassword(email);

    if (!error) {
      this.setState({
        resetPasswordModalOpen: false,
        title: 'Erfolg!',
        text: 'Ein Link zum Zurücksetzen des Passworts wird dir zugeschickt.'
      })
    } else {
      this.setState({
        resetPasswordModalOpen: false,
        isAlreadyConfirmed: false,
        title: 'Fehler',
        text: 'Etwas schief gelaufen. Versuchen Sie es bitte erneut.'
      })
    }
  };

  showMessage = (message, type = "error") =>
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type,
    });

  render() {
    const { title, text, isAlreadyConfirmed, success, isDisabled } = this.state;

    return (
      <div>
        <div className="confirmation container">
          <img src={logo} className="logo" />
          <div className="response-window">
            <p className="response-window__title">{title}</p>
            <div className="response-window__text">{text}</div>
            {success && (
              <>
                <div className="app-link-wrapper">
                  <a href="https://play.google.com/store/apps/details?id=com.bcipad" className="app-link app-link--google" />
                  <a href="https://apps.apple.com/de/app/bodyclub/id1510524141?l=en" className="app-link app-link--apple" />
                </div>
              </>
            )}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
};

export default Confirmation;