import React, { Component } from 'react';
import { Slide, toast } from "react-toastify";

import './styles.scss'
import api from "utils/api";
import logo from "images/logo.png";
import Footer from "../Footer";

class ForgotPassword extends Component {
  state = {
    email: '',
    modalOpen: false
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  };

  resetPassword = async () => {
    const { email } = this.state;

    const { error } = await api.resetPassword(email);

    if (!error) {
      this.setState({
        modalOpen: true,
        title: 'Erfolg!',
        text: 'Ein Link zum Zurücksetzen des Passworts wird dir zugeschickt.'
      })
    } else {
      this.setState({
        modalOpen: true,
        title: 'Fehler',
        text: 'Etwas schief gelaufen. Versuchen Sie es bitte erneut.'
      })
    }
  };

  showMessage = (message, type = "error") =>
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: "bottom-center",
      type,
    });

  render() {
    const { title, text, modalOpen, email } = this.state;

    return (
      <div>
        <div className="confirmation container">
          <img src={logo} className="logo" />
          {
            !modalOpen && (
              <div className="modal">
                <p className="modal__title">Passwort zurücksetzen</p>
                <p className="modal__text">Es existiert bereits ein Account mit dieser E-Mail-Adresse. Du kannst dich mit deinem Passwort einfach in der App anmelden. Solltest du dein Passwort vergessen haben, dann gib bitte unten deine E-Mail-Adresse an, du erhältst dann eine E-Mail, mit der du dein Passwort zurücksetzen kannst.</p>
                <div className="form__item" style={{ marginTop: 16 }}>
                  <label
                    className="form__label form__label--required"
                    htmlFor="email">
                    Email
                </label>
                  <input
                    type="text"
                    className="form__input"
                    name="email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="button-group">
                  <button style={{ marginRight: 0 }} className="button" onClick={this.resetPassword}>Speichern</button>
                </div>
              </div>
            )}
          {
            modalOpen && (
              <div className="response-window">
                <p className="response-window__title">{title}</p>
                <div className="response-window__text">{text}</div>
              </div>
            )
          }
        </div>
        <Footer />
      </div>
    )
  }
};

export default ForgotPassword;