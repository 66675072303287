import React, { Component } from 'react';
import { toast, Slide } from 'react-toastify';

import logo from 'images/logo.png';
import './styles.scss';
import api from 'utils/api';
import { WICKET } from 'utils/constants';
import getParameterByName from 'utils/queryString';
import Footer from 'components/Footer';

class Registration extends Component {
  state = {
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    successState: false,
    inputType: {
      password: 'password',
      confirmPassword: 'password',
    },
    cubeRegistration: false,
  };

  /**
   * Url param cubeRegistration is used for registration via ems cube.
   * This flag is send to backend to add the user to the corret group, membership, ...
   */
  componentDidMount() {
    //if parameter cubeRegistration is set to true, update the state
    if (getParameterByName('cubeRegistration') === 'true') {
      this.setState({ cubeRegistration: true });
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  registerUser = async () => {
    const { email, firstName, lastName, password, confirmPassword, cubeRegistration } = this.state;

    const payload = {
      email,
      firstName,
      lastName,
      password,
      username: email,
      cubeRegistration: cubeRegistration,
    };

    if (!payload.email) return this.showMessage('Email ist erforderlich!');
    if (!payload.firstName) return this.showMessage('Vorname ist erforderlich!');
    if (!payload.lastName) return this.showMessage('Name ist erforderlich!');
    if (!payload.password) return this.showMessage('Passwort ist erforderlich!');
    if (password !== confirmPassword) return this.showMessage('Passwörter stimmen nicht überein!');

    const { error } = await api.registerUser(payload);

    this.setState({
      loading: false,
      successState: !error,
    });

    if (error && error.detail === 'email.is.busy') {
      return this.props.history.push('/forgot-password');
    }

    if (error) {
      return this.showMessage(error.message);
    }
  };

  toggleInputTypeSwitch = (e) => {
    const { inputType } = this.state;

    this.setState({
      inputType: {
        ...inputType,
        [e.target.name]: this.state.inputType[e.target.name] === 'password' ? 'text' : 'password',
      },
    });
  };

  showMessage = (message, type = 'error') =>
    toast(message, {
      transition: Slide,
      closeButton: true,
      autoClose: 2000,
      position: 'bottom-center',
      type,
    });

  render() {
    const { email, firstName, lastName, password, confirmPassword, inputType, successState } = this.state;

    if (getParameterByName('wicket') !== WICKET)
      return (
        <div className='title'>
          <p>Sorry, wicket is wrong...</p>
        </div>
      );

    return (
      <div>
        <div className='registration container'>
          <img src={logo} className='logo' />
          {successState ? (
            <div className='title'>
              <p>Du bist dabei!</p>
              <p>
                Wir haben dir soeben eine E-Mail gesendet. Bitte prüfe dein Eingangspostfach und gegebenenfalls auch deinen Spam-Ordner! Bitte bestätige deine E-Mail-Adresse über den beigefügten Link
                und los geht's!
              </p>
            </div>
          ) : (
            <>
              <div className='title'>
                <p>Upgrade!</p>
                <p>Heb' Dein EMS Training auf das nächste Level, mit kostenfreiem Testangebot</p>
              </div>
              <div className='form'>
                <div className='form__row'>
                  <div className='form__item'>
                    <label className='form__label form__label--required' htmlFor='email'>
                      Email
                    </label>
                    <input className='form__input' value={email} name='email' onChange={this.handleChange} />
                  </div>
                </div>
                <div className='form__row'>
                  <div className='form__item'>
                    <label className='form__label form__label--required' htmlFor='firstName'>
                      First Name
                    </label>
                    <input className='form__input' value={firstName} name='firstName' onChange={this.handleChange} />
                  </div>
                  <div className='form__item'>
                    <label className='form__label form__label--required' htmlFor='lastName'>
                      Last Name
                    </label>
                    <input type='text' className='form__input' name='lastName' value={lastName} onChange={this.handleChange} />
                  </div>
                </div>
                <div className='form__row'>
                  <div className='form__item'>
                    <label className='form__label form__label--required' htmlFor='password'>
                      Temporary Password
                    </label>
                    <input type={inputType.password} className='form__input' value={password} name='password' onChange={this.handleChange} />
                    <button className='form__input-type-switcher' name='password' onClick={this.toggleInputTypeSwitch} />
                  </div>
                  <div className='form__item'>
                    <label className='form__label form__label--required' htmlFor='email'>
                      Confirm Password
                    </label>
                    <input type={inputType.confirmPassword} className='form__input' name='confirmPassword' value={confirmPassword} onChange={this.handleChange} />
                    <button className='form__input-type-switcher' name='confirmPassword' onClick={this.toggleInputTypeSwitch} />
                  </div>
                </div>
                <button className='button' onClick={this.registerUser}>
                  Los Geht's!
                </button>
              </div>
            </>
          )}
        </div>
        <Footer />
      </div>
    );
  }
}

export default Registration;
