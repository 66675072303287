import React from 'react';
import './styles.scss';

const Footer = () => {
  return (
    <div className="footer">
      <a href="https://bodyclub24.com/impressum/" className="footer__link">Impressum</a>
      <a href="https://bodyclub24.com/datenschutz/" className="footer__link">Datenschutz</a>
      <a href="https://bodyclub24.com/agb/" className="footer__link">AGB</a>
    </div>
  );
};

export default Footer;