import Registration from "components/Registration";
import { ToastContainer } from 'react-toastify';
import { Router, Route } from "react-router-dom";
import history from "./utils/history";
import 'react-toastify/dist/ReactToastify.css';
import Confirmation from "components/Confirmation";
import ForgotPassword from "components/ForgotPassword";

function App() {
  return (
    <div>
      <Router history={history}>
        <Route path="/confirm-email" exact component={Confirmation} />
        <Route path="/forgot-password" exact component={ForgotPassword} />
        <Route path="/60dayspromo" exact component={Registration} />
        <Route path="/" exact component={Registration} />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
