import axios from 'axios';
import { get } from 'lodash';
import { messageServiceUrl, userServiceUrl } from 'config/environment';

const getFlowId = () => {
  return Math.random().toString().substr(2);
}

const registerUser = (data) => {
  return axios({
    url: `${ userServiceUrl }/api/users/online-studio/v2.0/?wicket=fNnA4HAZedqq45H9Mz4Ea8PKwTeW4WgZt4u6`,
    method: 'post',
    data: data,
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({response: response.data}))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to activate user account"
      }
    }))
};

const activateUserAccount = (id) => {
  return axios({
    url: `${ messageServiceUrl }/api/send-mail/confirm-email/v1.0/?code=${ id }`,
    method: 'put',
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({response: response.data}))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to activate user account"
      }
    }))
};

const resetPassword = (email) => {
  return axios({
    url: `${ messageServiceUrl }/api/send-mail/forgot/v1.0/?email=${ email }`,
    method: 'post',
    headers: {
      'X-Flow-Id': getFlowId()
    }
  })
    .then(response => ({response: response.data}))
    .catch(err => ({
      error: {
        ...get(err, "response.data", {}),
        message: "Failed to activate user account"
      }
    }))
};
//
export default  {
  activateUserAccount,
  registerUser,
  resetPassword
}